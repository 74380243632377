import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in the headers
axiosInstance.interceptors.request.use(
  config => {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcxLCJpYXQiOjE3MzM2NzQ2OTgsImV4cCI6MTc2NTIzMjI5OH0.YMTJW5rtluvMPWQFmTjg5k07vpbLqNUeJpb8Vq7ejgM';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;